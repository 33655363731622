<template>
  <div class="mb-4">
    <div class="btn-group">
      <!-- Para tablero -->
      <span class="btn btn-success no-cursor"
        v-if="
          seccion == 'tablero' &&
          seccion != 'requerimientos' &&
          seccion != 'cotizaciones' &&
          seccion != 'ordenes-compra' &&
          seccion != 'provedores'
        "
      >
        General
      </span>
      <router-link
        :to="{ name: 'tableroCompras' }"
        v-if="
          seccion != 'tablero' ||
          seccion == 'requerimientos' ||
          seccion == 'cotizaciones' ||
          seccion == 'ordenes-compra' ||
          seccion == 'provedores'
        "
        class="btn btn-white"
      >
        General
      </router-link>
      <!-- Fin para tablero -->

      <!-- Para requerimiento -->
      <span class="btn btn-success no-cursor"
        v-if="
          seccion != 'tablero' &&
          seccion == 'requerimientos' &&
          seccion != 'cotizaciones' &&
          seccion != 'ordenes-compra' &&
          seccion != 'provedores'
        "
      >
        Requerimientos
      </span>
      <router-link
        :to="{ name: 'listadoRequerimientos' }"
        v-if="
          seccion == 'tablero' ||
          seccion != 'requerimientos' ||
          seccion == 'cotizaciones' ||
          seccion == 'ordenes-compra' ||
          seccion == 'provedores'
        "
        class="btn btn-white"
      >
        Requerimientos
      </router-link>
      <!-- Fin para requerimiento -->

      <!-- Para cotizaciones -->
      <span class="btn btn-success no-cursor"
        v-if="
          seccion != 'tablero' &&
          seccion != 'requerimientos' &&
          seccion == 'cotizaciones' &&
          seccion != 'ordenes-compra' &&
          seccion != 'provedores'
        "
      >
        Cotizaciones
      </span>
      <router-link
        :to="{ name: 'listadoCotizaciones' }"
        v-if="
          seccion == 'tablero' ||
          seccion == 'requerimientos' ||
          seccion != 'cotizaciones' ||
          seccion == 'ordenes-compra' ||
          seccion == 'provedores'
        "
        class="btn btn-white"
      >
        Cotizaciones
      </router-link>
      <!-- Fin para cotizaciones -->

      <!-- Para ordenes de compra -->
      <span class="btn btn-success no-cursor"
        v-if="
          seccion != 'tablero' &&
          seccion != 'requerimientos' &&
          seccion != 'cotizaciones' &&
          seccion == 'ordenes-compra' &&
          seccion != 'provedores'
        "
      >
        Ordenes de compra
      </span>
      <router-link
        :to="{ name: 'listadoOrdenesCompra' }"
        v-if="
          seccion == 'tablero' ||
          seccion == 'requerimientos' ||
          seccion == 'cotizaciones' ||
          seccion != 'ordenes-compra' ||
          seccion == 'provedores'
        "
        class="btn btn-white"
      >
        Ordenes de compra
      </router-link>
      <!-- Fin para ordenes de compra -->

      <!-- Para ordenes de compra -->
      <span class="btn btn-success no-cursor"
        v-if="
          seccion != 'tablero' &&
          seccion != 'requerimientos' &&
          seccion != 'cotizaciones' &&
          seccion != 'ordenes-compra' &&
          seccion == 'proveedores'
        "
      >
        Proveedores
      </span>
      <router-link
        :to="{ name: 'listadoProveedores' }"
        v-if="
          seccion == 'tablero' ||
          seccion == 'requerimientos' ||
          seccion == 'cotizaciones' ||
          seccion == 'ordenes-compra' ||
          seccion != 'proveedores'
        "
        class="btn btn-white"
      >
        Proveedores
      </router-link>
      <!-- Fin para ordenes de compra -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['seccion']
}
</script>
