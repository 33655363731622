<template>
  <Layout tituloPagina="Compras | Ordenes de compra">
    <div class="row">
      <div class="col-lg-12">
        <cmp-secciones seccion="ordenes-compra"></cmp-secciones>
      </div>
    </div>

    <br>
 
    <div class="row">
      <div class="col-lg-2 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-filter"></i>
            Estado
          </span>
          <select class="form-select">
            <option >Todos</option>
            <option >Sin seguimiento</option>
            <option >Aprobado</option>
            <option >Rechazado</option>
            <option >Pospuesto</option>
            <option >Cancelado</option>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-filter"></i>
            Proveedor
          </span>
          <select class="form-select">
            <option >Todos</option>
            <option >SYSCOM</option>
            <option >Purificadora San Martin</option>
            <option >Perfiles San Martin</option>
            <option >URREA</option>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-filter"></i>
            Fecha
          </span>
          <select class="form-select">
            <option >Seleccionar</option>
            <option >Dia</option>
            <option >Mes</option>
            <option >Año</option>
            <option >Personalizado</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="input-group">
          <input class="form-control"/>
          <span class="input-group-text">
            <i class="fa fa-calendar"></i>
          </span>
          <span class="input-group-text">
            al
          </span>
          <input class="form-control"/>
          <span class="input-group-text">
            <i class="fa fa-calendar"></i>
          </span>
          <button class="btn btn-success">
            <i class="mdi mdi-refresh"></i>
          </button>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-right">
        <button class="btn btn-success" @click="seleccionarCotizacion()">
          <i class="fa fa-plus"></i> Nueva cotización
        </button>
      </div>
    </div>

    <br />
    
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Numero de orden</th>
                <th>Fecha de autorización</th>
                <th>Nombre de la orden</th>
                <th>Proveedor</th>
                <th>División de familia de orden</th>
                <th>Estado</th>
                <th style="width:200px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  259
                </td>
                <td>
                  31/05/2020
                </td>
                <td>
                  Orden para cotización 327
                </td>
                <td>
                  SYSCOM
                </td>
                <td>
                  Equipo
                </td>
                <td>
                  Generada
                </td>
                <td>
                  <router-link
                    :to="{ name: 'visorOrdenCompra', params: { id:10 } }"
                    class="btnInfo btn btn-default btn-xs"
                  >
                    <i class="fa fa-eye"></i>
                    ver
                  </router-link>
                  <router-link
                    :to="{ name: 'edicionOrdenCompra', params: { id:10 } }"
                    class="btnEditar btn btn-default btn-xs"
                  >
                    <i class="fa fa-edit"></i>
                    Editar
                  </router-link>
                  <button class="btnBorrar btn btn-default btn-xs" @click="cancelarOrden(10)">
                    <i class="fa fa-ban"></i>
                    Cancelar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarRequerimientos()
        "
      ></paginador>
    </div>
    <mdl-seleccion-cotizacion ref="mdlSeleccionCotizacion"/>
    <br>
  </Layout>
</template>

<script>
import CmpSecciones from '../CmpSecciones.vue'
import Paginador from '@/components/Paginador.vue'
import MdlSeleccionCotizacion from './MdlSeleccionCotizacion.vue'
import Layout from "@/views/layouts/main"

export default {
  components: {
    CmpSecciones, 
    Paginador,
    MdlSeleccionCotizacion,
    Layout
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: 10,
      tc: 0, // Total de clientes
      totpag: 0, // Total de páginas
    }
  },
  created() {
    
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      //self.refrescarRequerimientos({ page: n })
    },

    seleccionarCotizacion: function () {
      var self = this

      self.$refs.mdlSeleccionCotizacion.mostrar()
    },
    
    cancelarOrden: function(idOrden) {
      swal({
        title: "¿Cancelar orden de compra?",
        text: "¿Está seguro de cancelar la orden de compra 259?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        closeOnConfirm: false,
        closeOnCancel: false
      },
      function(isConfirm) {
        if (isConfirm) {
          swal("Cancelado!!", "Orden de compra 259 cancelada", "success");
        } else {
          swal("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  }
}
</script>

<style></style>
